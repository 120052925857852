import React from "react";
import { Link } from "react-router-dom";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import Helper from "../../Helper/helper";
import BillingDetailsLoader from "../../Loader/BillingDetailsLoader";
import SubscriptionModal from "../../Modals/AddOnModal/Subscription";

import { t, translate } from "react-multi-lang";

class BillingDetailsComponent extends Helper {
  state = {
    subscriptions: [],
    loading: true,
    redirect: false,
    data: {},
    user: {},
    loadingContent: null,
    buttonDisable: false,
    cancelled_status: 0,
    showModalSubscription: false,
    showModal: false,
    SubscriptionModal: false,
    isDoublePayment: false,
  };

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseSubscription() {
    this.setState({ showModalSubscription: false });
  }

  handleOpenModal() {
    this.setState({ showModalSubscription: true });
    //alert('sini buat popup ya');
  }

  getUserDetails() {
    api.postMethod("profile").then((response) => {
      if (response.data.success === true) {
        let data = response.data.data;
        this.setState({ loading: false, user: data });
      }
    });
  }
  componentDidMount() {

    this.getUserDetails()

    // api call
    const data = {
      sub_profile_id: "",
    };

    api.postMethod("subscribedPlans", data).then((response) => {
      console.log(response);
      if (response.data.success) {
        if (response.data.data != "") {
          this.setState({
            loading: false,
            subscriptions: response.data.data,
            cancelled_status: response.data.data
              ? response.data.data[0].cancelled_status
              : 0,
          });
        } else {
          this.setState({
            loading: false,
            subscriptions: response.data.data,
          });
          this.errorCodeChecker(response.data.error_code);
        }
      } else {
        this.props.history.push("/account");
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });

    if (localStorage.getItem("userId") === "4") {
      window.location = "/login";
    }
  }

  handleSub = (e) =>{
    e.preventDefault();
    api.postMethod("emailsub", this.state.data).then(response => {
      
      ToastDemo(this.props.toastManager,'Subscription update',"success");
      this.setState({
        user:{
          email_notification:response.data.email_notification
        }
      })
  });

    console.log(e)
  }

  handleCancelAutoRenewal = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: t("loading_text"),
      buttonDisable: true,
    });
    let inputData = {
      cancel_reason: this.state.data.cancel_reason,
    };
    api
      .postMethod("cancel/subscription", inputData)
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            cancelled_status: 1,
          });
          window.$("#cancel-subs").modal("toggle");
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  handleEnableAutoRenewal = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: t("loading_text"),
      buttonDisable: true,
    });
    let inputData = {
      cancel_reason: this.state.data.cancel_reason,
    };
    api
      .postMethod("autorenewal/enable", inputData)
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            cancelled_status: 0,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  render() {
    const {
      loading,
      subscriptions,
      data,
      user,
      cancelled_status,
      showModalSubscription,
      isDoublePayment,
    } = this.state;
    // if (loading) {
    //   return "Loading...";
    // } else {
    //   if (subscriptions.length == 0) {
    //     this.props.history.push("/account");
    //     ToastDemo(this.props.toastManager, "No Data found", "error");
    //   }
    // }

    const hauto = {
      height:'auto'
    };
  

    return (
      <div>
        <div className="main padding-top-md">
          {loading ? (
            <BillingDetailsLoader />
          ) : (
            <div className="top-bottom-spacing">
              <div className="row">
                <div className="col-sm-10 col-md-11 col-lg-9 col-xl-8 auto-margin">
                  <div className="row m-0">
                    <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0">
                      {loading ? (
                        t("loading")
                      ) : subscriptions.length > 0 &&
                        subscriptions[0].active_plan === 1 ? (
                        <div
                          className="billing-img"
                          style={{
                            backgroundColor: "#343a40",
                          }}
                        >
                          <div className="billing-img-overlay">
                            <div className="display-inline">
                              <div className="icon-left">
                                <h4 className="billing-head"></h4>
                              </div>
                              <div className="content-right">
                                <h4 className="billing-head">{t("my_plan")}</h4>

                                <div
                                  className="btn btn-sky-blue rounded-pill btn-right-space br-0"
                                  onClick={() => this.handleOpenModal()}
                                >
                                  <i className="fas mr-2" />
                                  Add Package
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <p className="grey-line" />
                            <div className="display-inline">
                              <div className="icon-left">
                                <h5 className="billing-head"></h5>
                              </div>
                              <div className="content-right">
                                <h5 className="billing-head mb-3">
                                  {subscriptions[0].title}
                                </h5>
                                <p className="m-0">
                                  {subscriptions[0].currency}
                                  {this.formatRupiah(
                                    subscriptions[0].amount
                                  )} / {subscriptions[0].plan_formatted}
                                </p>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <p className="grey-line" />
                            <div className="display-inline">
                              <div className="icon-left">
                                <h5 className="billing-head"></h5>
                              </div>
                              <div className="content-right">
                                <h5 className="billing-head mb-3">
                                  {t("expiry_date")}
                                </h5>
                                <p className="m-0">
                                  {subscriptions[0].expiry_date}
                                </p>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <p className="grey-line" />
                          </div>
                        </div>
                      ) : (
                        <>{t("no_data_found")}</>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 p-0">
                      <div className="billing-content-sec" style={hauto}>
                        <h4 className="billing-head">{t("my_plan_detail")}</h4>
                        <p className="grey-line" />
                        {loading ? (
                          ""
                        ) : subscriptions.length > 0 &&
                          subscriptions[0].active_plan === 1 ? (
                          <div
                            className="card"
                            key={subscriptions[0].user_subscription_id}
                          >
                            <div className="card-header bg-dark text-white">
                              {subscriptions[0].title}
                            </div>
                            <div className="card-body">
                              <table className="table table-bordered m-0">
                                <tbody>
                                  <tr>
                                    <td>{t("order_id")}</td>
                                    <td>{subscriptions[0].payment_id}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("subscribed_date")}</td>
                                    <td>{subscriptions[0].created_at}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("expiry_date")}</td>
                                    <td>{subscriptions[0].expiry_date}</td>
                                  </tr>
                                  {/* <tr>
                                        <td>{t("no_of_account")}</td>
                                        <td>{subscription.no_of_account}</td>
                                      </tr> */}
                                  <tr>
                                    <td>{t("no_of_month")}</td>
                                    <td>{subscriptions[0].plan}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("total_amount")}</td>
                                    <td>
                                      {subscriptions[0].currency}
                                      {this.formatRupiah(
                                        subscriptions[0].total_amount
                                      )}
                                    </td>
                                  </tr>
                                  {subscriptions[0].wallet_amount > 0 ? (
                                    <tr>
                                      <td>{t("referral_amount")}</td>
                                      <td>
                                        {subscriptions[0].currency}
                                        {subscriptions[0].wallet_amount}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}

                                  {subscriptions[0].coupon_code == "" ? (
                                    ""
                                  ) : (
                                    <tr>
                                      <td>{t("coupon_amount")}</td>
                                      <td>
                                        {subscriptions[0].currency}
                                        {this.formatRupiah(
                                          subscriptions[0].coupon_amount
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>{t("paid_amount")}</td>
                                    <td>
                                      {subscriptions[0].currency}
                                      {this.formatRupiah(
                                        subscriptions[0].amount
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{t("payment_mode")}</td>
                                    <td>{subscriptions[0].payment_mode}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <>{t("no_data_found")}</>
                        )}
                      </div>
                      <form className="auth-form" onSubmit={this.handleSub}>
                        <button className="btn btn-danger auth-btn mt-4">{user.email_notification ? 'Unsubscribe ': 'Subscribe '} Email</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <SubscriptionModal
          show={showModalSubscription}
          onHide={this.handleCloseSubscription.bind(this)}
          isDoublePayment={isDoublePayment}
        />
      </div>
    );
  }
}

export default withToastManager(translate(BillingDetailsComponent));
