import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../Environment";

import Slider from "../SliderView/MainSlider";
import HomePageBanner from "./homePageBanner";
import HomeLoader from "../Loader/HomeLoader";
import { translate } from "react-multi-lang";
import Helper from "../Helper/helper";
import SliderHome from "./Slider/SliderHome";
import "./Home.scss"
import IsChromeModal from "../Modals/IsChromeModal/IsChomeModal"

import {
  isMobile,
  isTablet,
  isSmartTV,
  isBrowser,
  isAndroid,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
} from 'react-device-detect';

class Home extends Helper {
  state = {
    maindata: null,
    errorHandle: 0,
    loading: true,
    banner: null,
    loadingHomeCatSection: true,
    homeCatData: null,
    slider: null,
    tags:[],
    showModal: false
  };

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  async componentDidMount() {
    if (!isChrome) {
      this.setState({
        showModal: true
      });
    }
    const inputData = {
      page_type: "HOME",
    };
    await api
      .postMethod("home_first_section", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let maindata = response.data.data;
          let banner = response.data.banner;

          this.setState({
            loading: false,
            maindata: maindata,
            banner: banner,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
          this.errorCodeChecker(response.data.error_code);
        }
      })
      .catch(function(error) {});
    
      await api.postMethod("v4/tags/list")
      .then((response) => {
        this.setState({tags: response.data.data})
        // alert(JSON.stringify(this.state.tags))
      })
      .catch(function(error) {});

    await api
      .postMethod("home_category_section", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            loadingHomeCatSection: false,
            homeCatData: response.data.data
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
      await this.getSlider()
      // await this.handleScroll()

      
  }

  handleScroll = () => {
    var sectionIdCategoryID = document.getElementById(`PREFIXPROGRAM-1`);
    if (sectionIdCategoryID !== null) {
      let headerOffset = 70;
      let elementPosition = sectionIdCategoryID.getBoundingClientRect().top;
      let offsetPosition = elementPosition - headerOffset;
      document.querySelector('body').scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
    }
  }

  dashing(str){
    return str.replace(/ /g, "-");
  }

  renderVideoList = (maindata, index, type) => {
    // console.log('tes:'+index)
    let first
    if(index === 0 && type === 'default' && maindata.data.length){
      first = true
    }
    return (
      
      <React.Fragment key={index}>
        <div id={this.dashing(maindata.title)}></div>
        <div className="main-slidersec">
          <Link
            to={{
              pathname: "/view-all",
              state: {
                url_type: maindata.url_type,
                url_type_id: maindata.url_type_id,
                page_type: "HOME",
                title: maindata.title,
              },
            }}
          >
            <h3  className="">
              {maindata.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>
          
          <Slider >
            {maindata.data.map((movie) => (
              <Slider.Item movie={movie} key={movie.admin_video_id} activefirstVideoDetail={maindata.data[0]} first={first}>
                item1
              </Slider.Item>
            ))}
          </Slider>
          
        </div>
      </React.Fragment>
    );
  };

  renderVideoNoData = (maindata, index) => {
    return (
      ''
    );
  };

  async getSlider() {
    await api
      .postMethod("sliders/list", {page: "Home"})
      .then(async (response) => {       
        await this.setState({
          slider: response.data.data,
        });
      })
      .catch(function(error) {});
  }

  render() {
    const { t } = this.props;
    // const tags = ['Comika Talent', 'AGUSTUS 2021', 'Group/Duo', 'Bundling', 'Digital Download For You', 'Bahasa Daerah', 'Keadaan Kahar', 'SEPTEMBER']
    const {
      loading,
      maindata,
      banner,
      loadingHomeCatSection,
      homeCatData,
      slider,
      tags,
      showModal
    } = this.state;

    console.log(process.env.NODE_ENV)
    return (
      <div className="main-sec-content">
        {slider ?
        <div className="mb-5 mt-5">
          <SliderHome items={slider}></SliderHome>
        </div>
        :
        ''}
        
        
        {loading ? <HomeLoader /> : <HomePageBanner banner={banner} />}
        <div className="main p-40 home-slider-top">
          {/* {renderMyList} */}

          {loading
            ? ""
            : maindata.map((mainDa, index) =>
                mainDa.data.length === 0
                  ? this.renderVideoNoData(mainDa, index, 'default')
                  : loading
                  ? t("loading")
                  : this.renderVideoList(mainDa, index, 'default')
              )}
          {loadingHomeCatSection
            ? ""
            : homeCatData.map((mainDa, index) =>
                mainDa.data.length === 0
                  ? ""
                  : loading
                  ? t("loading")
                  : this.renderVideoList(mainDa, index, 'homeSec')
              )}

          <div className="height-100" />
          <h5 className="mb-5 title-margin-left">Popular Tags</h5>
            <div className="tag-container  d-flex">
              {
              tags.length > 0
              ?
                (tags.map((tag, index) => {
                  return <Link
                  to={{
                    pathname: "/view-all",
  
                    state: {
                        tag_id:
                        tag.id,
                        title: tag.name,
                        videoType: "tags",
                        apiURL: "v4/tags/videos"
                    }
                }}
                  className="tag-item" key={index}>{tag.name}</Link>
                }
                ))
                :
              ""
              }
              
            </div>
        </div>
        <IsChromeModal
            show={showModal}
            onHide={this.handleCloseModal.bind(this)}
          />
      </div>
    );
  }
}

export default translate(Home);
